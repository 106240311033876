<template>
    <div class="vw2">
        <div class="top-icon font12">
            <el-button @click="$router.go(-1)" type="warning" class="el-icon-arrow-left">
                {{ $t('message.Back') }}
            </el-button>
            <span>{{ $t('message.REFA History') }}</span>
            <el-button @click="logout()" type="danger" class="el-icon-unlock color-pora">
                {{ $t('message.Exit') }}
            </el-button>
        </div>
        <div class="my-tabs-card">
            <el-row :gutter="20">
                <el-col v-for="(item, index) in sale_quality_control_times_history" :key="'sale_quality_control_times_history-' + index" :span="8">
                    <div :class="getClassItem(item)">
                        <div class="itme-r-u">
                            <span>{{ $t('message.Working shift') }}:</span>
                            <b>{{ getWorkingShiftName(item) }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.sale') + ' №' }}:</span>
                            <b>{{ (item.quality_control_employee && item.quality_control_employee.sale_quality_control) ? item.quality_control_employee.sale_quality_control.sale_id : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.product') }}:</span>
                            <b>{{ (item.quality_control_employee && item.quality_control_employee.sale_quality_control && item.quality_control_employee.sale_quality_control.sale_product && item.quality_control_employee.sale_quality_control.sale_product.product) ? item.quality_control_employee.sale_quality_control.sale_product.product.name : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Process') }}:</span>
                            <b>{{ (item.quality_control_employee && item.quality_control_employee.sale_product_process && item.quality_control_employee.sale_product_process.process) ? item.quality_control_employee.sale_product_process.process.name : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Check') }}:</span>
                            <b>{{ (item.check_time) ? (item.check_time.name + ' (' + item.check_time.start_time + ' - ' + item.check_time.end_time + ')') : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Bant') }}:</span>
                            <b>{{ (item.quality_control_employee && item.quality_control_employee.bant) ? item.quality_control_employee.bant.name : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Employee') }}:</span>
                            <b>{{ (item.quality_control_employee && item.quality_control_employee.employee) ? item.quality_control_employee.employee.name : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Machine') }}:</span>
                            <b>{{ (item.quality_control_employee && item.quality_control_employee.machine) ? (item.quality_control_employee.machine.name + ' (' + item.quality_control_employee.machine.code + ')') : '' }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Number of verified') }}:</span>
                            <b>{{ item.checked_quantity }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Not passed OTK') }}:</span>
                            <b>{{ item.defect_quantity }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Mistakes') }}:</span>
                            <b>{{ getMistakes(item) }}</b>
                        </div>
                        <div class="itme-r-u">
                            <span>{{ $t('message.Measurement time') }}:</span>
                            <b>{{ item.created_at }}</b>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row v-show="!loadingData" :gutter="20">
                <el-col :span="24" class="text-center">
                    <el-button @click="incrementPaginationPage" :loading="loadingData" type="primary" icon="el-icon-arrow-down" round>{{ $t('message.Load more') }}</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import list from "@/utils/mixins/includes/list";

export default {
    mixins: [list],
    components: {},
    data() {
        return {
            
        };
    },
    computed: {
        ...mapGetters({            
            sale_quality_control_times_history: 'saleQualityControls/sale_quality_control_times_history',
            pagination: 'saleQualityControls/pagination',
        }),
    },
    methods: {
        ...mapActions({
            updateList: "saleQualityControls/getSaleQualityControlTimesHistory",
            updatePagination: "saleQualityControls/updatePagination",
            incrementPaginationPage: "saleQualityControls/incrementPaginationPage",            
        }),
        getClassItem(item) {            
            let class_name = 'rght-user-info-re ';

            if (item.defect_quantity == 0) {
                class_name += 'good';
            }
            else if (item.defect_quantity > 0) {
                class_name += 'bed';
            }

            return class_name;
        },
        getWorkingShiftName(item) {
            let working_shift = (item.quality_control_employee && item.quality_control_employee.sale_quality_control && item.quality_control_employee.sale_quality_control.working_shift) ? item.quality_control_employee.sale_quality_control.working_shift : null;

            if (working_shift) {
                return (working_shift.name + ' (' + working_shift.start_time + ' - ' + working_shift.end_time + ')');
            }
            else {
                return ''
            }
        },
        getMistakes(item) {
            var result = '';
            _.forEach(item.employee_mistakes, function(item2, key) {
                if (key === 0) {
                    result += item2.name;
                }
                else {
                    result += (', ' + item2.name);
                }
            });

            return result;
        },
        logout() {
            this.$confirm('Вы уверены, что хотите выйти?', 'Подтверждение', {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch("auth/logout");
                    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                }).catch(() => {
                
                });
        },
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('saleQualityControls/EMPTY_SET_SALE_QUALITY_CONTROL_HISTORY');
        next()
    },
};
</script>