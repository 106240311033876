export default {
    data() {
        return {
            loadingData: false,
        }
    },
    created() {        
        this.fetchData();        
        if (_.isFunction(this.incrementPaginationPage)) {
          this.debouncedIncrementPaginationPage = _.debounce(this.incrementPaginationPage, 1000);
        }
    },
    watch: {        
        'pagination.page': {
            handler: async function(newVal, oldVal) {                
                if (newVal != oldVal && _.isFunction(this.fetchData)) {                    
                    this.fetchData();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {        
        fetchData() {
            const query = { ...this.pagination };
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false
                }).catch(err => {
                    this.loadingData = false
                });
            }
        },
        
    },  
  }
  
  